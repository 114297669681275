import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Strong, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Infoproser - Rastreo Satelital
			</title>
			<meta name={"description"} content={"Conozca la ubicación de sus activos en todo momento"} />
			<meta property={"og:title"} content={"Infoproser - Rastreo Satelital"} />
			<meta property={"og:description"} content={"Conozca la ubicación de sus activos en todo momento"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16:53:10.740Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<meta name={"msapplication-TileColor"} content={"rgba(0, 8, 72, 0.02)"} />
		</Helmet>
		<Section sm-padding="8px 0 8px 0" quarkly-title="Header" overflow-x="visible" background="rgba(0, 0, 0, 0)">
			<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
			<Box
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="flex-start"
				flex-direction="row"
				width="30%"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-width="50%"
				lg-width="70%"
				md-justify-content="flex-start"
			>
				<Components.Logo>
					<Image
						src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16:53:10.740Z"
						display="block"
						object-fit="cover"
						width="250px"
						srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Components.Logo>
			</Box>
		</Section>
		<Section padding="160px 0 24px 0" sm-padding="80px 0 24px 0" md-padding="50px 0 24px 0" quarkly-title="acceso-clientes">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				flex-direction="column"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					position="static"
					lg-left="0px"
					md-width="100%"
					border-radius="10px"
					lg-max-height="366px"
					src="https://images.unsplash.com/photo-1666281466387-0639381c5680?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					left="-140px"
					max-width="100%"
					object-fit="cover"
					srcSet="https://images.unsplash.com/photo-1666281466387-0639381c5680?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1666281466387-0639381c5680?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1666281466387-0639381c5680?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1666281466387-0639381c5680?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1666281466387-0639381c5680?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1666281466387-0639381c5680?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1666281466387-0639381c5680?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				justify-content="space-between"
				empty-min-height="64px"
				empty-border-style="solid"
				width="50%"
				padding="16px 16px 16px 16px"
				display="flex"
				md-width="100%"
				empty-min-width="64px"
				empty-border-width="1px"
				empty-border-color="LightGray"
				flex-direction="column"
				align-items="flex-start"
			>
				<Text
					width="100%"
					lg-width="100%"
					lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 24px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--indigo"
					sm-font="normal 800 30px/1.2 &quot;Inter&quot;, sans-serif"
					quarkly-title="titulo"
				>
					Rastreo Satelital en Tiempo Real
				</Text>
				<Text
					font="--lead"
					opacity=".7"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 25px 0px"
					color="--dark"
				>
					En nuestra plataforma AVL tienes el conocimiento de la ubicación de tus activos en tiempo real, con alertas de pánico, corte de motor, reportes, geocercas, reenvió de datos (integraciones a otras plataformas).
				</Text>
				<Link
					href="https://avl.infoproserspa.cl/?lang=es"
					sm-padding="15px 20px 15px 20px"
					hover-background="rgba(5, 165, 255, 0)"
					border-width="1px"
					border-style="solid"
					font="--lead"
					sm-margin="0px 22px 0px 0px"
					border-color="#3b5fa8"
					md-width="100%"
					md-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					text-decoration-line="initial"
					color="--white"
					margin="0px 44px 0px 0px"
					hover-color="--primary"
					hover-border-color="--color-primary"
					md-text-align="center"
					background="--color-azulinfo"
					padding="15px 30px 15px 30px"
					border-radius="10px"
					sm-font="normal 500 18px/1.6 &quot;Inter&quot;, sans-serif"
					transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
					target="_blank"
					display="inline-block"
					text-align="center"
				>
					Acceso Clientes
				</Link>
			</Box>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			quarkly-title="bienvenida"
			background="#3b5fa8"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Bienvenido a
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Infoproser
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Somos tu socio confiable en soluciones GPS e instalación de accesorios vehiculares de vanguardia. Con más de una década de experiencia en el sector, estamos aquí para satisfacer tus necesidades con productos y servicios de la más alta calidad.
			</Text>
		</Section>
		<Section lg-padding="60px 0 40px 0" padding="30px 0 30px 0" md-padding="30px 0 30px 0" quarkly-title="nuestros-servicios">
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--indigo"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
			>
				Nuestros Servicios
			</Text>
			<Box
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				padding="0px 0px 0px 0px"
				border-style="solid"
				display="flex"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
				width="100%"
				border-color="rgba(66, 82, 207, 0.1)"
				md-border-color="rgba(66, 82, 207, 0.1)"
			>
				<Box display="flex" lg-width="50%" md-width="100%" width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://images.unsplash.com/photo-1555371363-27a37f8e8c46?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="contain"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						flex="1 1 0%"
						srcSet="https://images.unsplash.com/photo-1555371363-27a37f8e8c46?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1555371363-27a37f8e8c46?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1555371363-27a37f8e8c46?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1555371363-27a37f8e8c46?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1555371363-27a37f8e8c46?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1555371363-27a37f8e8c46?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1555371363-27a37f8e8c46?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					padding="32px 17% 32px 62px"
					width="50%"
					justify-content="space-between"
					md-width="100%"
					md-padding="32px 30px 32px 0px"
					display="flex"
					flex-direction="column"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
				>
					<Text
						color="--indigo"
						width="155%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
						margin="0px 0px 8px 0px"
						font="--headline4"
						align-self="stretch"
						height="auto"
						flex="0 0 auto"
						order="-1"
						justify-content="flex-start"
						align-items="flex-start"
						display="flex"
						text-align="left"
					>
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Soluciones en Gestión de Flotas
						</Strong>
						{"\n\n"}
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						flex="1 1 0%"
						order="0"
						align-self="auto"
						display="block"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" width="150%" padding="10% 0px 0px 0px">
							¿Buscas optimizar el rendimiento de tu flota? ¡Nosotros tenemos la solución! Con nuestro avanzado sistema de seguimiento y gestión por GPS, podrás agilizar las operaciones de tu flota de manera eficiente y efectiva. ¡Descubre cómo podemos llevar tu negocio al siguiente nivel!{"\n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				md-border-width="0px 0px 2px 0px"
				margin="0px 0px 20px 0px"
				width="100%"
				border-style="solid"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				border-radius="10px"
				flex-wrap="wrap"
				min-height="284px"
				md-border-radius="0px"
			>
				<Box
					padding="32px 17% 32px 62px"
					width="50%"
					display="flex"
					position="relative"
					md-padding="32px 30px 32px 0px"
					flex-direction="column"
					justify-content="space-between"
					lg-padding="32px 30px 32px 32px"
					lg-width="50%"
					md-width="100%"
				>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="150%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
						flex="0 1 auto"
						text-align="center"
					>
						<Strong>
							Servicios Personalizados de Geolocalización
						</Strong>
						{"\n\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--darkL1"
						width="150%"
						flex="1 1 0%"
					>
						¡Optimice su empresa con nuestros servicios personalizados de geolocalización! Obtenga geocercas y reportes basados en la ubicación diseñados específicamente para satisfacer las necesidades de su negocio. Mejore la eficacia de sus rutas y lleve su empresa al siguiente nivel. ¡Descubra cómo podemos ayudarle hoy mismo!{"\n\n"}
					</Text>
				</Box>
				<Box
					md-order="-1"
					width="50%"
					display="flex"
					lg-width="50%"
					md-width="100%"
				>
					<Image
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						margin="-1px -1px -1px 0px"
						src="https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						srcSet="https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1618861859575-b6e43d10922a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
			>
				<Box md-width="100%" width="50%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://images.unsplash.com/photo-1625217527288-93919c99650a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						width="100%"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						srcSet="https://images.unsplash.com/photo-1625217527288-93919c99650a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1625217527288-93919c99650a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1625217527288-93919c99650a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1625217527288-93919c99650a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1625217527288-93919c99650a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1625217527288-93919c99650a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1625217527288-93919c99650a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 17% 32px 62px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="50%"
					flex-direction="column"
					justify-content="space-between"
				>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline4"
						color="--indigo"
						width="150%"
						lg-font="600 22px/1.3 &quot;Inter&quot;, sans-serif"
					>
						<Strong>
							Control Total de Activos: App para Seguimiento e Informes en Tiempo Real
						</Strong>
						{"\n\n"}
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="--lead"
						color="--darkL1"
						width="150%"
						flex="1 1 0%"
					>
						¡Manténgase informado sobre sus activos en todo momento con nuestra aplicación móvil! Con nuestra app, obtenga información en tiempo real y reciba informes detallados para tomar decisiones fundamentadas. ¡Descargue ahora y lleve el control de sus activos a un nivel superior!{"\n\n"}
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="30px 0 30px 0" quarkly-title="proveedores" background="--color-azulinfo">
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="--headline3"
				color="--light"
				border-color="--color-light"
			>
				<Strong>
					Nuestros Proveedores: Garantía de Calidad
				</Strong>
				{"\n\n"}
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="--lead"
				sm-margin="0px 0px 50px 0px"
				color="--lightD1"
			>
				En Infoproser, nos comprometemos a trabajar solo con proveedores de confianza y calidad probada. Gracias a nuestra sólida red de socios comerciales, podemos ofrecer productos y servicios de la más alta calidad a nuestros clientes. Confíe en nosotros para proporcionar soluciones confiables y eficaces respaldadas por los mejores proveedores del mercado.{"\n\n"}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(6, 1fr)"
				grid-gap="0 54px"
				max-width="100%"
				align-items="center"
				width="100%"
				lg-grid-template-columns="repeat(3, 1fr)"
				lg-grid-gap="36px 72px"
				sm-grid-template-columns="repeat(2, 1fr)"
				color="--light"
				align-self="stretch"
				justify-items="stretch"
				align-content="space-around"
				background="--color-light"
				flex="1 1 0%"
			>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.04%20PM.jpeg?v=2024-02-11T18:41:44.722Z"
					display="block"
					width="100%"
					object-fit="contain"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.04%20PM.jpeg?v=2024-02-11T18%3A41%3A44.722Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.04%20PM.jpeg?v=2024-02-11T18%3A41%3A44.722Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.04%20PM.jpeg?v=2024-02-11T18%3A41%3A44.722Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.04%20PM.jpeg?v=2024-02-11T18%3A41%3A44.722Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.04%20PM.jpeg?v=2024-02-11T18%3A41%3A44.722Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.04%20PM.jpeg?v=2024-02-11T18%3A41%3A44.722Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.04%20PM.jpeg?v=2024-02-11T18%3A41%3A44.722Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.35%20PM.jpeg?v=2024-02-11T18:41:44.720Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.35%20PM.jpeg?v=2024-02-11T18%3A41%3A44.720Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.35%20PM.jpeg?v=2024-02-11T18%3A41%3A44.720Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.35%20PM.jpeg?v=2024-02-11T18%3A41%3A44.720Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.35%20PM.jpeg?v=2024-02-11T18%3A41%3A44.720Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.35%20PM.jpeg?v=2024-02-11T18%3A41%3A44.720Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.35%20PM.jpeg?v=2024-02-11T18%3A41%3A44.720Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.49.35%20PM.jpeg?v=2024-02-11T18%3A41%3A44.720Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.10%20PM.jpeg?v=2024-02-11T18:41:44.718Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.10%20PM.jpeg?v=2024-02-11T18%3A41%3A44.718Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.10%20PM.jpeg?v=2024-02-11T18%3A41%3A44.718Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.10%20PM.jpeg?v=2024-02-11T18%3A41%3A44.718Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.10%20PM.jpeg?v=2024-02-11T18%3A41%3A44.718Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.10%20PM.jpeg?v=2024-02-11T18%3A41%3A44.718Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.10%20PM.jpeg?v=2024-02-11T18%3A41%3A44.718Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.10%20PM.jpeg?v=2024-02-11T18%3A41%3A44.718Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.56%20PM.jpeg?v=2024-02-11T18:41:44.727Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.56%20PM.jpeg?v=2024-02-11T18%3A41%3A44.727Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.56%20PM.jpeg?v=2024-02-11T18%3A41%3A44.727Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.56%20PM.jpeg?v=2024-02-11T18%3A41%3A44.727Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.56%20PM.jpeg?v=2024-02-11T18%3A41%3A44.727Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.56%20PM.jpeg?v=2024-02-11T18%3A41%3A44.727Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.56%20PM.jpeg?v=2024-02-11T18%3A41%3A44.727Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.50.56%20PM.jpeg?v=2024-02-11T18%3A41%3A44.727Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="30px 0 30px 0" quarkly-title="proyectos">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="--headline2"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--indigo"
				border-color="--color-indigo"
			>
				Funcionalidades Personalizadas
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="--lead"
				color="--dark"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				En Infoproser, entendemos que cada cliente es único. Es por eso que ofrecemos una amplia gama de funcionalidades personalizadas y construidas a medida para satisfacer las necesidades específicas de tu empresa. Confía en nosotros para proporcionarte soluciones que se adapten perfectamente a tus requerimientos y impulsen el éxito de tu negocio.{"\n\n"}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
				margin="0px 0px 10px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
					width="50%"
					margin="0px 0px 10px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/_df0f22e6-0d7f-4189-8418-f8b8eb80e32d.jpg?v=2024-02-11T19:40:38.657Z"
						display="block"
						max-width="100%"
						height="300px"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_df0f22e6-0d7f-4189-8418-f8b8eb80e32d.jpg?v=2024-02-11T19%3A40%3A38.657Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_df0f22e6-0d7f-4189-8418-f8b8eb80e32d.jpg?v=2024-02-11T19%3A40%3A38.657Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_df0f22e6-0d7f-4189-8418-f8b8eb80e32d.jpg?v=2024-02-11T19%3A40%3A38.657Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_df0f22e6-0d7f-4189-8418-f8b8eb80e32d.jpg?v=2024-02-11T19%3A40%3A38.657Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_df0f22e6-0d7f-4189-8418-f8b8eb80e32d.jpg?v=2024-02-11T19%3A40%3A38.657Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_df0f22e6-0d7f-4189-8418-f8b8eb80e32d.jpg?v=2024-02-11T19%3A40%3A38.657Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_df0f22e6-0d7f-4189-8418-f8b8eb80e32d.jpg?v=2024-02-11T19%3A40%3A38.657Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="--headline4">
							<Span font-weight="normal">
								{"\n"}Máxima Seguridad en Todo Momento{"\n\n"}
							</Span>
						</Text>
						<Text margin="0px 0px 25px 0px" font="--lead" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Nuestro sistema de GPS cuenta con alertas de seguridad integradas para brindar tranquilidad a nuestros clientes. En caso de emergencias o situaciones de riesgo, nuestro sistema notifica tanto a los clientes como a la central de manera inmediata. Con nosotros, tu seguridad siempre es una prioridad.{"\n\n\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
					width="50%"
					margin="0px 0px 10px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/_361d36c0-9f9a-48d6-a776-e75f8b2c5005.jpg?v=2024-02-11T19:56:40.092Z"
						display="block"
						max-width="100%"
						height="300px"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						object-position="30%70%"
						srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_361d36c0-9f9a-48d6-a776-e75f8b2c5005.jpg?v=2024-02-11T19%3A56%3A40.092Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_361d36c0-9f9a-48d6-a776-e75f8b2c5005.jpg?v=2024-02-11T19%3A56%3A40.092Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_361d36c0-9f9a-48d6-a776-e75f8b2c5005.jpg?v=2024-02-11T19%3A56%3A40.092Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_361d36c0-9f9a-48d6-a776-e75f8b2c5005.jpg?v=2024-02-11T19%3A56%3A40.092Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_361d36c0-9f9a-48d6-a776-e75f8b2c5005.jpg?v=2024-02-11T19%3A56%3A40.092Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_361d36c0-9f9a-48d6-a776-e75f8b2c5005.jpg?v=2024-02-11T19%3A56%3A40.092Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_361d36c0-9f9a-48d6-a776-e75f8b2c5005.jpg?v=2024-02-11T19%3A56%3A40.092Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="--headline4">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font-weight="normal"
							>
								Control Eficiente de Combustible
							</Span>
							<br />
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 25px 0px" font="--lead" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Nuestra solución de GPS ofrece monitoreo en tiempo real del consumo de combustible, proporcionando una herramienta efectiva para reducir pérdidas por robos y fugas. Con nuestro sistema, podrás mantener un control preciso de tus recursos y optimizar la eficiencia de tu operación.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
					width="50%"
				>
					<Image
						src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/_d7a0aef9-19fa-4765-b7cc-7bcdd6e257ae.jpg?v=2024-02-11T20:05:05.524Z"
						display="block"
						max-width="100%"
						height="300px"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						object-position="0% 50%"
						srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d7a0aef9-19fa-4765-b7cc-7bcdd6e257ae.jpg?v=2024-02-11T20%3A05%3A05.524Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d7a0aef9-19fa-4765-b7cc-7bcdd6e257ae.jpg?v=2024-02-11T20%3A05%3A05.524Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d7a0aef9-19fa-4765-b7cc-7bcdd6e257ae.jpg?v=2024-02-11T20%3A05%3A05.524Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d7a0aef9-19fa-4765-b7cc-7bcdd6e257ae.jpg?v=2024-02-11T20%3A05%3A05.524Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d7a0aef9-19fa-4765-b7cc-7bcdd6e257ae.jpg?v=2024-02-11T20%3A05%3A05.524Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d7a0aef9-19fa-4765-b7cc-7bcdd6e257ae.jpg?v=2024-02-11T20%3A05%3A05.524Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d7a0aef9-19fa-4765-b7cc-7bcdd6e257ae.jpg?v=2024-02-11T20%3A05%3A05.524Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="--headline4">
							<Span font-weight="normal">
								Controla tu Entorno
							</Span>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 25px 0px" font="--lead" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Con nuestra herramienta de GPS, podrás crear fácilmente geocercas personalizadas para delimitar zonas de operación. Recibe alertas instantáneas al entrar o salir de estas áreas, proporcionándote un control total sobre tu entorno operativo. Optimiza tus operaciones y mantén la seguridad con nuestra solución avanzada.{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
					width="50%"
				>
					<Image
						src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/_d38ad613-101f-4b53-8e51-d8dd5d26e392.jpg?v=2024-02-11T20:08:56.205Z"
						display="block"
						max-width="100%"
						height="300px"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						object-position="30%70%"
						srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d38ad613-101f-4b53-8e51-d8dd5d26e392.jpg?v=2024-02-11T20%3A08%3A56.205Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d38ad613-101f-4b53-8e51-d8dd5d26e392.jpg?v=2024-02-11T20%3A08%3A56.205Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d38ad613-101f-4b53-8e51-d8dd5d26e392.jpg?v=2024-02-11T20%3A08%3A56.205Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d38ad613-101f-4b53-8e51-d8dd5d26e392.jpg?v=2024-02-11T20%3A08%3A56.205Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d38ad613-101f-4b53-8e51-d8dd5d26e392.jpg?v=2024-02-11T20%3A08%3A56.205Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d38ad613-101f-4b53-8e51-d8dd5d26e392.jpg?v=2024-02-11T20%3A08%3A56.205Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/_d38ad613-101f-4b53-8e51-d8dd5d26e392.jpg?v=2024-02-11T20%3A08%3A56.205Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="--headline4">
							<Span font-weight="normal">
								Análisis de Rendimiento en Tiempo Real
							</Span>
							{"\n\n"}
						</Text>
						<Text margin="0px 0px 25px 0px" font="--lead" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Nuestro sistema de GPS genera informes detallados de rendimiento para que las empresas puedan analizar y mejorar la eficiencia de sus flotas. Con datos precisos y en tiempo real, podrás tomar decisiones informadas para optimizar tus operaciones y maximizar tu productividad.{"\n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="30px 0 30px 0" quarkly-title="proveedores" background="--color-azulinfo">
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="--headline3"
				color="--light"
				border-color="--color-light"
			>
				<Strong>
					Nuestros Clientes: La Base de Nuestro Éxito
				</Strong>
				{"\n\n"}
			</Text>
			<Text
				margin="0px 0px 30px 0px"
				text-align="center"
				font="--lead"
				sm-margin="0px 0px 50px 0px"
				color="--lightD1"
			>
				En Infoproser, nos enorgullece contar con una sólida base de clientes actuales que confían en nuestros servicios y soluciones de vanguardia. Desde pequeñas empresas hasta grandes corporaciones, nuestra diversa cartera de clientes refleja nuestra capacidad para adaptarnos a las necesidades individuales de cada cliente y proporcionarles resultados excepcionales. Trabajamos en estrecha colaboración con nuestros clientes actuales para garantizar su satisfacción continua y el éxito mutuo.{"\n\n"}
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(6, 1fr)"
				grid-gap="0 54px"
				max-width="100%"
				align-items="center"
				lg-grid-template-columns="repeat(3, 1fr)"
				lg-grid-gap="36px 72px"
				sm-grid-template-columns="repeat(2, 1fr)"
				color="--light"
				align-self="stretch"
				justify-items="stretch"
				align-content="space-around"
				background="--color-light"
				flex="1 1 0%"
			>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM.jpeg?v=2024-02-11T20:12:51.603Z"
					display="block"
					width="100%"
					object-fit="contain"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.55.09%20PM.jpeg?v=2024-02-11T20:12:51.610Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.55.09%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.55.09%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.55.09%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.55.09%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.55.09%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.55.09%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.55.09%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.47.15%20PM.jpeg?v=2024-02-11T20:12:51.610Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.47.15%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.47.15%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.47.15%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.47.15%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.47.15%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.47.15%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.47.15%20PM.jpeg?v=2024-02-11T20%3A12%3A51.610Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM%20%281%29.jpeg?v=2024-02-11T20:12:51.599Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM%20%281%29.jpeg?v=2024-02-11T20%3A12%3A51.599Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM%20%281%29.jpeg?v=2024-02-11T20%3A12%3A51.599Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM%20%281%29.jpeg?v=2024-02-11T20%3A12%3A51.599Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM%20%281%29.jpeg?v=2024-02-11T20%3A12%3A51.599Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM%20%281%29.jpeg?v=2024-02-11T20%3A12%3A51.599Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM%20%281%29.jpeg?v=2024-02-11T20%3A12%3A51.599Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.37.00%20PM%20%281%29.jpeg?v=2024-02-11T20%3A12%3A51.599Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.37%20PM.jpeg?v=2024-02-11T20:12:51.603Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.37%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.37%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.37%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.37%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.37%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.37%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.37%20PM.jpeg?v=2024-02-11T20%3A12%3A51.603Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.11%20PM.jpeg?v=2024-02-11T20:12:51.604Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.11%20PM.jpeg?v=2024-02-11T20%3A12%3A51.604Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.11%20PM.jpeg?v=2024-02-11T20%3A12%3A51.604Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.11%20PM.jpeg?v=2024-02-11T20%3A12%3A51.604Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.11%20PM.jpeg?v=2024-02-11T20%3A12%3A51.604Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.11%20PM.jpeg?v=2024-02-11T20%3A12%3A51.604Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.11%20PM.jpeg?v=2024-02-11T20%3A12%3A51.604Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/WhatsApp%20Image%202024-02-04%20at%208.42.11%20PM.jpeg?v=2024-02-11T20%3A12%3A51.604Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="10px 0 30px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 60px 0px 60"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="30px 0px 30px 0px"
					font="--headline2"
					color="--indigo"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0 0px 0px 0px"
				>
					Contactanos
				</Text>
				<Text margin="0px 0px 30px 0px" font="--lead" color="--dark" text-align="center">
					¿Listo para llevar tu negocio al siguiente nivel con nuestras soluciones de vanguardia? ¡Contáctanos hoy mismo y descubre cómo podemos ayudarte a alcanzar tus objetivos! Nuestro equipo experto está aquí para responder a todas tus preguntas, brindarte asesoramiento personalizado y proporcionarte la mejor experiencia posible. Ya sea por teléfono, correo electrónico, estamos listos para escucharte y trabajar juntos para el éxito de tu empresa. ¡Esperamos con ansias conocerte y ser parte de tu viaje hacia el éxito!{"\n\n"}
				</Text>
				<Text margin="0px 0px 0 0px" font="--headline4" color="--azulinfo" text-align="center">
					(+56) 9 5205 3345{" "}
				</Text>
				<Text margin="0px 0px 0 0px" font="--headline4" color="--azulinfo" text-align="center">
					<Link href="mailto:contacto@infoproser.cl" text-decoration-line="initial">
						contacto@infoproserspa.cl
					</Link>
					{"\n\n"}
				</Text>
				<Link
					href="https://www.instagram.com/infoproser?igsh=MW42bHE1Nzk3ZXYzeg=="
					color="--azulinfo"
					link-order="0"
					font="24px sans-serif"
					align-self="auto"
					order="0"
					target="_blank"
				>
					@InfoproserSpa
				</Link>
			</Box>
		</Section>
		<Section padding="50px 0 50px 0" quarkly-title="Footer-2">
			<Box display="flex" justify-content="space-between" sm-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="#5a5d64"
					sm-margin="0px 0px 15px 0px"
					sm-text-align="center"
				>
					© 2024 Infoproser Spa.
				</Text>
			</Box>
		</Section>
		<Link href="#" color="#000000">
			Some text
		</Link>
		<Link href="#" color="#000000">
			Some text
		</Link>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});