import React from "react";
import { useOverrides } from "@quarkly/components";
import { LinkBox } from "@quarkly/widgets";
const defaultProps = {
	"flex-direction": "row",
	"href": "/index",
	"display": "flex",
	"grid-gap": "12px"
};
const overrides = {};

const Logo = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <LinkBox {...rest}>
		{children}
	</LinkBox>;
};

Object.assign(Logo, { ...LinkBox,
	defaultProps,
	overrides
});
export default Logo;